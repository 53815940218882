import React, { memo, Suspense } from 'react';
import Spinner from './Components/Spinner/Spinner';

import './assets/css/App.css';
const RouterPath = React.lazy(() => import('./Components/RouterPath'));

function App() {
 
  return (
    <Suspense fallback={<Spinner />}>
      <div>
        <RouterPath />
      </div>
    </Suspense>
  );
}

export default memo(App);
