import React, { Fragment  } from "react";
import { memo } from "react";

function Spinner() {
  return (
    <Fragment>
      <div  className="d-flex justify-content-center my-5">
        <div  className="spinner-grow elegant-color-dark" style={{width: '6rem', height: '6rem'}} role="status">
       
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </Fragment>
  );
}
// @@DiosTeAmoCoderZay@@
export default memo(Spinner);
