import firebase from 'firebase/app';
import {analytics}  from 'firebase';

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyBpyF_tTHDLEDq3j6PmqGtFXgsjhJZ5hcw",
  authDomain: "coderzay-93e03.firebaseapp.com",
  databaseURL: "https://coderzay-93e03.firebaseio.com",
  projectId: "coderzay-93e03",
  storageBucket: "coderzay-93e03.appspot.com",
  messagingSenderId: "773113033278",
  appId: "1:773113033278:web:581a8d3521e1f35fa4976f",
  measurementId: "G-R0TGJFPKC0"
};

firebase.initializeApp(firebaseConfig);

analytics();

